// eslint-disable-next-line import/prefer-default-export
export const barsClubsHero = {
  title: "Top-shelf brewery & bar POS system",
  subtext:
    "Streamline operations and boost revenue with a lightning-fast POS system built for bars & nightclubs. ",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/nightclub-and-bar-pos/demo",
  },
};
export const barsClubsSubHero = {
  title: "Better tech to serve your guests — and increase sales",
  subtext:
    "Your guests want fast, easy options to order and pay, so they can spend less time chasing down their drink and more time enjoying it. With SpotOn, it’s never been easier to give them what they want—with powerful, integrated tools that help you save time, grow revenue, and create an unforgettable guest experience.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/nightclub-and-bar-pos/demo",
  },
};

export const barsClubsWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "Let someone serve you for a change",
  content:
    "You're constantly operating on the fly. Let SpotOn lighten your load with first class support, served straight up. Our team will work closely with you to customize the software and point-of-sale technology you need, provide in-person installation and training, and continue to be there anytime you need us with 24/7 support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/nightclub-and-bar-pos/demo",
  },
};

export const videoHighlights = {
  mainTitle: "Create better experiences for your staff & guests",
  quote: `“I find that my closing time is significantly faster with SpotOn’s reporting. My staff is 80% paper-free, which means we don’t spend all that time putting tips in at the end of the night. It’s already done. I’ve cut my close time down from 2 hours to 30 minutes.”`,
  quoteAuthor: "Hillary Holmes",
  quoteAuthorPosition: "Von Elrod's Beer Hall + Kitchen",
  videoId: "ov0_ok37nYU",
  posterImg: "bars-highlights-poster.png",
};

export const barsClubsLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Far more than a POS",
      blockSubTitle:
        "With an end-to-end solution that is far more than any ordinary POS, SpotOn will help you tap your bar or nightclub’s earning potential. Transform your operations with QR ordering and payment options, convenient handhelds for lightning-fast ordering and payments, elite reporting, marketing and review management, loyalty programs, and so much more.",
      blockImg: "bars-x.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/nightclub-and-bar-pos/demo",
      },
    },
  ],
};

export const barsClubsTrusted = [
  {
    title: "5+",
    subtext: "More tables served daily with handhelds",
  },
  {
    title: "18%",
    subtext: "Increase in average ticket with online ordering",
  },
  {
    title: "74%",
    subtext: "Of guests choose businesses with a strong loyalty reward program",
  },
];

export const barsClubsMultiSection = {
  sectionTitle: "Better tech built for bars & nightclubs",
  featureBlocks: [
    {
      blockTitle: "SpotOn Restaurant",
      blockSubTitle:
        "Work faster and smarter with a point-of-sale that combines all the features you need to create an amazing guest experience—and none of the hassle.",
      blockList: [
        "Custom menu build & table layouts",
        "Lightning-fast ordering & payments",
        "Pricing events & sales contests",
        "Real-time updates & reporting",
        "Remote back office",
        "Multi-location management",
      ],
      blockImg: "bars-a.png",
      ImgAltTag: "nightclub and bar pos",
      forceReverse: false,
      ctaInfo: {
        ctaTitle: "Learn more",
        ctaTarget: "/products/payments",
      },
    },
    {
      blockTitle: "SpotOn QR, helping you pour more shots",
      blockSubTitle:
        "When you’re four deep, having fast, convenient ways to order and pay are essential to keep your guests happy and improve revenue. With SpotOn QR, your bar or nightclub can speed up ordering by having customers scan a code at the bar or table to order and pay with their phone. Orders get sent to your POS in real-time to improve efficiency and reduce mistakes.",
      blockImg: "bars-b.png",
      ImgAltTag: "nightclub and bar pos",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/nightclub-and-bar-pos/demo",
      },
    },
    {
      blockTitle: "SpotOn Serve",
      blockSubTitle:
        "Take orders faster and turn more tables by equipping your servers with handhelds for order- and pay-at-the-table. SpotOn Serve is a cutting-edge, cloud-based POS handheld device that’s fast, durable, and easy to use.",
      blockList: [
        "Send orders to the bar and kitchen in real-time",
        "Built-in EMV, Apple Pay, and Android Pay enabled card reader with swiper for gift cards",
        "Built-in touchscreen display and thermal printer",
        "Digital signature-capture and receipts",
        "Extended range with Wi-Fi and 4G LTE connectivity options",
      ],
      blockImg: "bars-c.png",
      ImgAltTag: "nightclub and bar pos",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/nightclub-and-bar-pos/demo",
      },
    },
    {
      blockTitle: "SpotOn Loyalty, Marketing, and Review Management",
      blockSubTitle:
        "Turn first-time customers into lifelong regulars with a flexible digital rewards program designed to improve customer retention and boost revenue. With SpotOn Loyalty, offer your customers enrollment rewards and speed up the checkout process with multiple ways for your customers to check-in and redeem rewards, both in-person and online.",
      blockImg: "bars-d.png",
      ImgAltTag: "nightclub and bar pos",
      blockList: [
        "Grow your customer list with automated tools",
        "Easily send marketing emails and deals",
        "Improve your online reputation with automatic review prompts to loyal customers ",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/nightclub-and-bar-pos/demo",
      },
    },
    // {
    //   blockTitle: "Tools to run your bar",
    //   blockSubTitle:
    //     "We know your bar is busy, and you need the tools to efficiently run it. Our start-a-tab feature allows servers to swipe the card, add items, and quickly close out—without having to keep the guest’s credit card. Incremental authorization continuously checks available funds as the tab increases to protect you from fraud. Plus, run your restaurant more efficiently and smoothly with the SpotOn Manager App, which makes POS approvals and authorizations easy—anytime, anywhere.",
    //   blockImg: "bars-e.png",
    //   ImgAltTag: "nightclub and bar pos",
    //   forceReverse: false,
    //   ctaPrime: {
    //     ctaTitle: "Learn more",
    //     ctaTarget: "/nightclub-and-bar-pos/demo",
    //   },
    // },
  ],
};

export const barsClubsCTA = {
  title: "Book your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/nightclub-and-bar-pos/demo",
  },
};

export const barsClubsVideo = {
  title: "See SpotOn solutions in action",
  videoBtn: "Watch video",
  posterImg: "bars-poster.png",
  posterWrapperClass: "relative",
  videoUrlId: "JFBJAF9KGTs",
};

export const barsClubsTestimonials = {
  title: "What other restaurants are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const barsClubsFaqs = {
  title: "Frequently Asked Questions",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/page-2",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const barsClubsCar = {
  title: "Run a different type of restaurant",
  subtext: "Bars and Nightclubs are just one of our specialties.",
  punctuation: "?",
  slides: [
    {
      bg: "quick-dining.png",
      cardTitle: "Quick service restaurant",
      cardContent: "Work faster and smarter",
      icon: "qsr.png",
      linkTo: "/quick-service-pos",
    },
    {
      bg: "food-truck.png",
      cardTitle: "Food Trucks",
      cardContent: "Tech that can keep up with your food truck",
      icon: "food-trucks.png",
      linkTo: "/food-truck-pos",
    },
    {
      bg: "casual-dining.png",
      cardTitle: "Casual dining",
      cardContent: "Unlock your restaurant’s potential",
      icon: "casual-dining.png",
      linkTo: "/casual-dining-pos",
    },
    {
      bg: "fine-dining.png",
      cardTitle: "Fine Dining",
      cardContent: "Tailor-made restaurant solutions",
      icon: "fine-dining.png",
      linkTo: "/fine-dining-pos",
    },
  ],
};

export const rotarySection = {
  title: "Future-proofing bars & nightclubs",
  subtext: `Our team works hand-in-hand with owners, operators, and GMs from some of the finest bars and nightclubs in the nation to help them better connect with guests and increase margins. Learn how SpotOn has helped their businesses thrive.`,
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/",
  },
  data: [
    {
      businessName: "Von Elrod’s Beer Hall & Kitchen",
      businessType: "Beer Hall",
      thumbnail: "von-elrod-thumb.png",
      image: "von-elrod.png",
      location: "Nashville, TN",
      owner: "Hillary Holmes",
      website: "https://vonelrods.com/",
      paragraphs: [
        `Serving 4,000 beer hall guests is a numbers game. With SpotOn’s high-powered reporting and user-friendly handhelds and Kitchen Display System (KDS), Von Elrod’s increased kitchen efficiency, shortened customer wait times, and improved the guest experience. SpotOn technology also decreased Von Elrod’s labor spend while simultaneously improving average guest spend by $14 and server tip percentage by 2%, giving them an edge in a tough labor market.`,
        `“We’ve been able to deal with rising product costs with a smaller staff,” says Von Elrod’s GM, Hillary Holmes. “We’re able to run a leaner team with better spend controls. We use reporting to find out what time of day we’re getting bigger pushes to schedule breaks, later in- and out-times, and really focus on trends rather than just having 4 culinary team members waiting for guests to come in at 6 o’clock.”`,
      ],
    },
    {
      businessName: "Loma Brewing",
      businessType: "Bar & Nightclub",
      thumbnail: "loma-brewing-thumb.png",
      // image: 'loma-brewing.png',
      videoId: "JFBJAF9KGTs",
      location: "Los Gatos, CA",
      owner: "Kevin Youkilis",
      website: "www.lomabrew.com",
      paragraphs: [
        `With fewer servers on hand, and customers looking to reduce the amount of things their hands touch, former baseball player and owner of Loma Brewing, Kevin Youkilis had to figure out how to do more with less at his popular brew pub.`,
        `By introducing SpotOn’s QR codes to their ordering process, which enables customers to order and pay directly from their phone, staff are spending less time running from front-of-house to back-of-house, guests are spending more time enjoying their beer and each other’s company, and Youkilis and his staff are spending more time discovering craft beers to add to their menu.`,
      ],
    },
    {
      businessName: "Stogies Jazz Club & Listening Room",
      businessType: "Cigar bar and jazz listening room",
      thumbnail: "stogies-jass-club-thumb.png",
      image: "stogies-jass-club.png",
      location: "St. Augustine, FL",
      owner: "Jeff Holleran",
      website: "www.oldcitystogies.com",
      paragraphs: [
        `Owner Jeff Holleran has operated Stogies Jazz Club & Listening Room for more than 25 years, and he was looking for modern technology that would be quick for staff to learn and use, and could help improve guest experiences. With SpotOn, Stogies can serve more customers per hour thanks to increased efficiency.`,
      ],
      stats: [
        { 
          title: `20%`, 
          description: `increase in revenue` 
        },
        {
          title: `25-30%`,
          description: `increase in servers' tips`,
        },
      ],
      cta: {
        target: "/case-studies/stogies-jazz-club/",
        title: "Read the story",
      }
    },
    // {
    //   businessName: "Double Zero",
    //   businessType: "Restaurant",
    //   thumbnail: "double-zero-thumb.png",
    //   videoId: "nM9F-g6o3CY",
    //   location: "6 location",
    //   owner: "Matthew Kenney",
    //   website: "www.matthewkenneycuisine.com/hospitality",
    //   paragraphs: [
    //     `With 45 restaurants that run the gamut from fast casual to food halls to fine dining, CEO of Matthew Kenney Cuisine and vegan chef Matthew Kenney doesn’t have the time—or resource-center—to train staff on 45 different POS systems or sort through 45 different types of data. To keep his business running smoothly and efficiently, he needs a single solution that helps employees speak the same language across all of his restaurants, including at his six Double Zero location.`,
    //     `SpotOn Restaurant gives Kenney a single, unified system to give him insights into every aspect of his business—day by day, hour by hour. Having consistency in resource-center and reporting across business areas enables Kenney to make smarter decisions, quicker. Combined with online ordering from SpotOn, Double Zero has seen a 30 – 40% increase in weekly revenue over the previous year.`,
    //   ],
    // },
  ],
};
