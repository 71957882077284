import React, { useState } from "react";
import { string, shape } from "prop-types";
import { Typography } from "antd";
import YouTube from "react-youtube";
import VideoHighlightsStyles from "./styles";
import quotes from "../../images/svg/quotes.svg";
import shapeBg from "../../images/svg/shape-01.svg";
import Image from "../../components/ImageQuerys/PosterImages";
import playIcon from "../../images/global_assets/play_icon.png";
import Heading from "../Base/HeadingBuilder";

const { Title } = Typography;

const VideoHighlights = ({ sectionData }) => {
  const {
    mainTitle,
    quote,
    quoteAuthor,
    quoteAuthorPosition,
    videoId,
    posterImg,
  } = sectionData;

  const [playing, setPlaying] = useState(false);

  const onPlayerReady = (event) => {
    event.target.playVideo();
  };

  return (
    <section className="v-highs">
      <div className="v-highs__data-wrapper">
        <div className="v-highs__data">
          <div className="v-highs__title">
            <Heading level={2} injectionType={2}>
              {mainTitle}
              <span className="blue-sign">.</span>
            </Heading>
          </div>

          <div className="v-highs__info">
            <img
              className="v-highs__info--quotes-img"
              src={quotes}
              alt="quotes"
            />
            <p className="v-highs__info--quote">{quote}</p>
            <div className="v-highs__info--author">{quoteAuthor}</div>
            <div className="v-highs__info--author-position">
              {quoteAuthorPosition}
            </div>
            <img
              className="v-highs__info--shape"
              src={shapeBg}
              alt="background shape"
            />
          </div>
        </div>
      </div>

      <div className="v-highs__highlights--video-wrapper">
        {!playing ? (
          <div className="v-highs__poster-wrapper">
            <Image imageName={posterImg} className="v-highs__poster" />
            <button
              type="button"
              onClick={() => setPlaying(true)}
              className="v-highs__play-btn"
            >
              <img src={playIcon} alt="play icon" />
            </button>
          </div>
        ) : (
          <YouTube
            videoId={videoId}
            containerClassName="v-highs__youtube-wrapper"
            className="v-highs__highlights--video"
            onReady={onPlayerReady}
            opts={{
              playerVars: {
                autoplay: 1,
              },
              height: "100%",
              width: "100%",
            }}
          />
        )}
      </div>

      <VideoHighlightsStyles />
    </section>
  );
};

VideoHighlights.propTypes = {
  sectionData: shape({
    mainTitle: string,
    quote: string,
    quoteAuthor: string,
    quoteAuthorPosition: string,
    videoId: string,
  }).isRequired,
};

export default VideoHighlights;
