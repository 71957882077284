import React from "react";
import { colors, viewports } from "../../style-vars";

const VideoHighlightsStyles = () => (
  <style jsx global>{`
    .v-highs {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .v-highs__data-wrapper {
      background: ${colors.primaryBackground};
      width: 100%;
      position: relative;
    }
    .v-highs__data {
      display: flex;
      flex-direction: column;
      //background: ${colors.primaryBackground};
      padding: 80px 16px 250px;
      max-width: 1440px;
      margin: 0 auto;
    }
    .v-highs__highlights--video-wrapper {
      position: relative;
      margin-top: -180px;
      width: 100%;
    }
    .v-highs__highlights--video {
      border-radius: 34px;
      width: 100%;
      padding: 16px;
    }
    .v-highs__title {
      flex: 0 1 100%;
    }
    .v-highs__info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 0 1 100%;
    }
    .v-highs__title h2 {
      color: ${colors.white} !important;
    }
    .v-highs__info--author {
      color: ${colors.white};
      font-weight: bold;
      font-size: 16px;
      margin-top: 24px;
    }
    .v-highs__info--quote {
      margin-top: 24px;
      color: #e4e6e9;
      font-size: 16px;
      font-weight: normal;
    }
    .v-highs__info--author-position {
      margin-top: 8px;
      color: #e4e6e9;
      font-size: 16px;
      font-weight: normal;
    }
    .v-highs__info--shape {
      align-self: flex-end;
      margin-top: -90px;
      height: 250px;
      position: absolute;
      right: 0;
      bottom: 20px;
    }

    .v-highs__poster-wrapper {
      position: relative;
      margin: 0 auto;
      width: 90%;
    }
    .v-highs__poster {
      position: initial !important;
      width: 100%;
      padding: 16px;
      object-fit: scale-down !important;
      object-position: center;
      height: 350px;
    }
    .v-highs__poster img {
      border-radius: 24px;
      object-position: right;
    }
    .v-highs__play-btn {
      width: 116px;
      position: absolute;
      left: 30%;
      top: 30%;
      background: none;
      border: none;
      outline: none;
      transition: 0.3s;
    }
    .v-highs__play-btn:hover {
      cursor: pointer;
      opacity: 0.8;
    }
    .v-highs__youtube-wrapper {
      height: 350px;
      width: 100%;
      margin: 0 auto;
    }

    @media ${viewports.mdOrBigger} {
      .v-highs__data {
        padding: 80px 32px 250px;
      }
      .v-highs__highlights--video {
        padding: 16px 32px;
      }
      .v-highs__poster {
        height: 400px;
      }
      .v-highs__youtube-wrapper {
        height: 400px;
      }
      .v-highs__play-btn {
        width: 136px;
        left: 42%;
        top: 35%;
      }
    }

    @media ${viewports.lgOrBigger} {
      .v-highs__data {
        flex-direction: row;
        padding: 110px 60px 400px;
      }
      .v-highs__title {
        flex: 0 1 50%;
      }
      .v-highs__info {
        flex: 0 1 50%;
      }
      .v-highs__info--shape {
        height: auto;
        margin-right: -60px;
        height: 350px;
      }
      .v-highs__title h2 {
        width: 85%;
      }
      .v-highs__highlights--video-wrapper {
        margin-top: -300px;
        width: 90%;
      }
      .v-highs__poster {
        height: 570px;
      }
      .v-highs__highlights--video {
        height: 570px;
      }
      .v-highs__play-btn {
        left: 47%;
        top: 40%;
      }
    }

    @media ${viewports.xlOrBigger} {
      .v-highs__data {
        padding: 110px 150px 400px;
      }
      .v-highs__title h2 {
        width: 70%;
      }
    }

    @media screen and (min-width: 1600px) {
      .v-highs__highlights--video-wrapper {
        width: 60%;
      }
      .v-highs__play-btn {
        left: 39%;
        top: 38%;
      }
    }
  `}</style>
);

export default VideoHighlightsStyles;
