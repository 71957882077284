import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import BHero from "../../components/Hero/b-hero";
import {
  barsClubsHero,
  barsClubsSubHero,
  barsClubsLargeFeatureA,
  barsClubsTrusted,
  barsClubsMultiSection,
  barsClubsCTA,
  barsClubsVideo,
  barsClubsTestimonials,
  barsClubsCar,
  videoHighlights,
  rotarySection,
} from "../../data/subverticals/restaurants/bar-and-clubs-data";
import { viewports } from "../../style-vars";

import heroBg from "../../images/hero-images/bars-hero.png";
import Articles from "../../components/Articles/Articles";
import { restaurantArticles } from "../../data/restaurant-data";
import VideoHighlights from "../../components-v2/VideoHighlights";

const NoVisualsCustom = loadable(() =>
  import("../../components/NoVisuals/NoVisualsCustom")
);
const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../components/CTAs/LargeCta"));

const VideoSection = loadable(() =>
  import("../../components/VideoSection/VideoSection")
);
const TestmonialReviews = loadable(() =>
  import("../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../components/BusinessTypes/BusinessTypes")
);
const RotarySection = loadable(() =>
  import("../../components-v2/RotarySection")
);
// const CapterraBanner = loadable(() =>
//   import("../../components-v2/CapterraBanner")
// );
const TopRestaurantsLogos = loadable(() =>
  import("../../components-v2/TopRestaurantsLogos")
);

const partnersTitle = "Trusted by local restaurants & bars everywhere.";

const barsClubs = () => {
  return (
    <Layout>
      <SEO
        title="Bar & Nightclub Point of Sale (POS) System & Software | SpotOn"
        description="Our POS offers automated bar tabs, split checks, and real-time product management. SpotOn POS will help you increase profits. Raise your bar's revenue today."
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/nightclub-and-bar-pos"
        productSchemaSR
      />
      <BHero sectionData={barsClubsHero} heroBg="bars-hero.png" />
      <NoVisualsCustom sectionData={barsClubsSubHero} simple />
      <VideoHighlights sectionData={videoHighlights} />
      <LargeFeatures
        sectionData={barsClubsLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={barsClubsTrusted} />
      <LargeFeatures
        sectionData={barsClubsMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      {/* <CapterraBanner
        className="mt-16 lg:mt-20 mb-20 lg:mb-40"
        imageName="capterra-bar-pos.png"
        title={`Rated the <span class="text-primary">best</span> bar POS system by real users<span class="text-primary">.</span>`}
        withCta
        ctaLabel="See the results"
        ctaTarget="https://datainsights.capterra.com/p/bar-pos/226928/spoton/references?g=na&c=&c=&c=&sc=6321063"
      /> */}
      <LargeCta sectionData={barsClubsCTA} />
      <RotarySection sectionData={rotarySection} animations={false} />
      <VideoSection sectionData={barsClubsVideo} />
      <TopRestaurantsLogos sectionTitle={partnersTitle} />
      <TestmonialReviews sectionData={barsClubsTestimonials} />
      <Articles
        sectionData={restaurantArticles}
        blogFilters={{
          limit: 3,
          include: "tags,authors",
          filter: "tag:Restaurants",
        }}
        cornerStone={false}
        style={{ marginTop: 60 }}
      />
      <BusinessTypes sectionData={barsClubsCar} />
      {/* <Faqs sectionData={barsClubsFaqs} /> */}

      <style jsx global>{`
        section.bars.ant-layout section.rotary {
          margin-bottom: 90px !important;
        }

        .bars .hero-b {
          max-width: 1440px;
        }

        .bars .no-visuals-text {
          margin: 40px 3px;
          max-width: 1440px;
        }

        .bars .video-section,
        .bars .logos-wrapper,
        .bars section.cta,
        .bars .testimonial-reviews {
          max-width: 1440px;
          width: 100%;
        }

        .bars section.cta {
          padding: 20px 16px;
        }

        .bars .logos-wrapper {
          margin-bottom: 80px;
        }

        @media ${viewports.mdOrBigger} {
          .bars .hero-b,
          .bars .no-visuals-text {
            margin: 30px;
          }

          .bars .v-highs {
            margin: 30px 0;
          }

          .bars section.cta {
            padding: 30px;
          }

          .bars .rotary .extra-large-heading {
            font-size: 42px !important;
            line-height: 59px !important;
          }
          .bars section.rotary div.rotary__info {
            padding: 20px 40px !important;
          }

          section.bars.ant-layout section.rotary {
            margin-bottom: 0 !important;
          }
        }

        @media ${viewports.lgOrBigger} {
          .bars .business-types {
            margin-top: 150px;
          }
        }

        @media ${viewports.xlOrBigger} {
          .bars .large-features {
            max-width: 1440px;
          }
          .bars .rotary .extra-large-heading {
            width: 70%;
          }
          .bars section.rotary div.rotary__info p {
            width: 80%;
          }
        }
      `}</style>
    </Layout>
  );
};

export default barsClubs;
